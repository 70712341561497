$primary: #33A7AA;

@import "~bootstrap/scss/bootstrap";

$off-white: #FFF9F3;
.btn {
    &-primary {
        color: $white;
        &:hover {
            color: $white;
        }
    }
}


@import "./components/LandingPage/landing-page.scss"