.landing-page {
    background-image: url("./burger.png");
    background-position: right;
    // Note that the below streches the image.
    background-size: cover;
    background-repeat: no-repeat;

    min-height: 100%;

    &__left {
        background-color: $off-white
    }
}